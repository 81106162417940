<template>
    <section class="partners pattern">
        <h3 class="partners__title">
            {{$t('partners.how')}}
        </h3>
        <div class="partners__text">
            {{$t('partners.info')}}
        </div>
        <h3 class="partners__title">
            {{$t('partners.trust')}}
        </h3>
        <swiper ref="swiperPartners" :options="partnersOptions" class="partners-carousel" v-if="partners.length>0"> 
            <swiper-slide v-for="(item, idx) in partners" :key="idx">
                <div class="partners-carousel__item">
                    <img :src="$hostname + item.photo" alt="Logo">
                    <span></span>
                </div>
            </swiper-slide>
        </swiper>
        <h3 class="partners__title">
            {{$t('partners.form')}}
        </h3>
        <partners-form />
        <div class="about-sweets">
            <img src="../assets/img/waffles.png" alt="Cookies" class="about-sweets__cookies" 
            v-rellax="sweets">
            <img src="../assets/img/waffles.png" alt="Waffles" class="about-sweets__waffles" 
            v-rellax="sweets">
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import {partnersAPI} from '../api'
import PartnersForm from '../components/PartnersForm.vue'

export default {
    components: {
        Swiper, SwiperSlide, PartnersForm
    },
    created() {
        partnersAPI().then(response => this.partners = response.data.partners)
    },
    mounted() {
        this.$options.metaInfo.title = this.$i18n.t('meta.partners.title')
        this.$options.metaInfo.meta[0].content = this.$i18n.t('meta.partners.description')
        this.$options.metaInfo.meta[1].content = this.$i18n.t('meta.partners.keywords')
    },
    metaInfo: {
        title: '',
        meta: [
            { name: 'description', content: '' },
            { name: 'keywords', content: '' },
        ]
    },
    data() {
        return {
            sweets: {
                speed: 4
            },
            partners: [],
            partnersOptions: {
                speed: 500,
                slidesPerView: "auto"
            }
        }
    }
}
</script>

<style lang="less">
.partners {
    padding: 11rem 0 8rem;
    background: #fffdf9;

    &__title {
        font-weight: 600;
        font-size: 2.4rem;
        text-align: center;
    }

    &__text {
        max-width: 42rem;
        padding: 0 1rem;
        font-size: 1.2rem;
        line-height: 167%;
        color: #545454;
        margin: 1.5rem auto 3rem;
        text-align: center;
    }

    &-carousel {
        margin: 2rem 0 5rem;

        &__item {
            position: relative;
            
            img {
                width: 100%;
                max-height: 10rem;
                object-fit: contain;
                position: relative;
                z-index: 2;
            }

            span {
                width: 7rem;
                height: 7rem;
                border-radius: 50%;
                display: block;
                background: var(--yellow);
                margin: -3rem auto 0;
                transition: .3s all;
                transform: scale(0);
                transform-origin: center top;
            }

            &:hover span {
                transform: none;
            }
        }
    }


    .about-sweets__cookies {
        bottom: 30vh;
        left: -5rem;
    }

    .about-sweets__waffles {
        width: 20rem;
    }
}

@media (max-width: 900px) {
    .partners  .about-sweets img{
        width: 10rem;
    }
}

@media (max-width: 767px), (max-width: 1080px) and (orientation: portrait) {
    .partners {
        padding: 8rem 0 4rem;

        &__title {
            font-size: 1.8rem;
        }


        &-carousel {
            margin-bottom: 2rem;
        }

        &-form {

            &__wrap {
                width: 100%;
            }
        }
    }
}
</style>

<style scoped>
.pattern::before, .pattern::after {
    background-image: url(../assets/img/pattern-gray.png);
    background-size: contain;
}

.swiper-slide {
    width: 9rem;
    margin: 0 1.5rem;
}
</style>
