<template>
    <section class="history">
        <div class="container">
            <div class="history__text wow fadeInUp" data-wow-delay="1s">
                {{$t('history.text')}}
            </div>
            <div class="history__title">
                {{$t('history.title')}}
            </div>
            <div class="history-date" v-if="showSlider">
                <swiper ref="swiperDate" :options="dateOptions" class="history-date__carousel
                wow fadeInLeft" data-wow-delay=".5s">
                    <swiper-slide v-for="(item,idx) in historyItems" :key="idx" class="history-date__carousel-item">
                        <div>
                            {{transformDate(item.date)}}
                        </div>
                        <span></span>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="history-carousel__wrap" v-if="showSlider">
                <swiper ref="swiperHistory" :options="historyOptions" class="history-carousel
                wow fadeInRight" data-wow-delay=".5s">
                    <swiper-slide v-for="(item,idx) in historyItems" :key="idx" class="history-carousel__item">
                        <div class="history-carousel__img">
                            <img :src="$hostname + item.photo" alt="history">
                        </div>
                        <div class="history-carousel__desc">
                            {{getLangDesc(item).substr(0, 150)+'...'}}
                        </div>
                        <button class="history-carousel__btn btn" @click="openPopup(idx)">
                            {{$t("buttons.learnMore")}}
                        </button>
                    </swiper-slide>
                </swiper>
                <div class="history-carousel__arrows">
                    <span @click="prevSlide">
                        <arrow-left />
                    </span>
                    <span @click="nextSlide">
                        <arrow-right />
                    </span>
                </div>
            </div>

            <transition name="fade">
                <div class="history-popup" v-if="popupOpen" @click.self="popupOpen=false">
                    <div class="history-popup__content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" 
                        class="history-popup__close" @click="popupOpen=false">
                            <g fill="none" fill-rule="evenodd" stroke="#2f2f2f" stroke-linecap="round" stroke-width="2.5">
                            <path d="M0,0 L17.903818,17.903818" transform="translate(2 2)"/><path d="M2.77111667e-13,0 L17.903818,17.903818" transform="matrix(-1 0 0 1 19.904 2)"/>
                            </g>
                        </svg>
                        <h3 class="history-popup__title">
                            {{popupTitle}}
                        </h3>
                        <div class="history-popup__text">
                        {{popupText}}
                        </div>
                    </div>
                </div>
            </transition>


            <history-prod />      
        </div>

        <div class="history-info">
            <img src="../assets/img/history-info.png" alt="History" class="wow fadeInLeft" data-wow-delay=".5s">
            <p class="history-info__text wow fadeInRight" data-wow-delay=".5s">
              {{$t('history.desc')}}  
            </p>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import {historyAPI} from '../api'
import HistoryProd from './HistoryProd.vue'
import ArrowLeft from './ArrowLeft.vue'
import ArrowRight from './ArrowRight.vue'

export default {
    components: {
        Swiper,		  
		SwiperSlide,
        HistoryProd,
        ArrowLeft, ArrowRight
    },
    async created() {
        await historyAPI().then(response => {
            this.historyItems = response.data.histories
            this.showSlider = true
            setTimeout(() => {
                this.$refs.swiperDate?.$swiper.init()
            }, 20)
        })
    },
    data() {
        return {
            historyItems: [],
            showSlider: false,
            popupTitle: '',
            popupText: '',
            popupOpen: false,

            dateOptions: {
                allowTouchMove: false,
                centeredSlides: true,
                loop: true,
                speed: 500,
                init: false,
                breakpoints: {
                    0: {
                        spaceBetween: 20,
                        slidesPerView: "3",
                    },

                    550: {
                        slidesPerView: "3",
                        speceBetween: 20,
                    },

                    900: {
                        spaceBetween: 30,
                        slidesPerView: "5",
                    },
                }
            },

            historyOptions: {
                allowTouchMove: false,
                centeredSlides: true,
                loop: true,
                spaceBetween: 30,
                speed: 500,
                slidesPerView: "1",
            }
        }
    },

    methods: {
        prevSlide() {
            this.$refs.swiperDate.$swiper.slidePrev()
            this.$refs.swiperHistory.$swiper.slidePrev()
        },

        nextSlide() {
            this.$refs.swiperDate.$swiper.slideNext()
            this.$refs.swiperHistory.$swiper.slideNext()
        },
        
        getLangDesc(item) {
            if(this.$root.$i18n.locale == 'uz') {
                return item.description_uz
            } else if (this.$root.$i18n.locale == 'en') {
                return item.description_en
            } else {
                return item.description_ru
            }
        },

        transformDate(date) {
            return date.replace(/\//gi, '.')
        },

        openPopup(i) {
            this.popupOpen = true
            if(this.$root.$i18n.locale == 'uz') {
                this.popupTitle = this.historyItems[i].name_uz
                this.popupText = this.historyItems[i].description_uz
            } else if (this.$root.$i18n.locale == 'en') {
                this.popupTitle = this.historyItems[i].name_en
                this.popupText = this.historyItems[i].description_en
            } else {
                this.popupTitle = this.historyItems[i].name_ru
                this.popupText = this.historyItems[i].description_ru
            }
            
        }
    }
}
</script>

<style lang="less">
.history {
    position: relative;
    overflow: hidden;
    background: #fffdf9;
    padding: 5rem 0;

    .container {
        position: relative;
        z-index: 1;
    }

    &::before, &::after {
        content: '';
        display: inline-block;
        width: 55rem;
        height: 55rem;
        background: url(../assets/img/pattern-gray.png) center center no-repeat;
        background-size: contain;
        pointer-events: none;
        position: absolute;
        z-index: 0;
    }

    &::before {
        top: 15rem;
        right: 0;
        transform: translate(50%, 50%);
    }

    &::after {
        left: 0;
        bottom: 15rem;
        transform: translate(-50%, -50%);
    }

    &__text {
        max-width: 55rem;
        margin: 0 auto 6rem;
        padding: 2rem;
        font-size: 1rem;
        line-height: 200%;
        background: #fff;
        border: .4rem solid #FFE185;
        box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.15);
        border-radius: 1.6rem;
        text-align: justify;
    }

    &__title {
        font-size: 2.4rem;
        font-weight: 600;
        text-align: center;
        margin-bottom: 2rem;
    }

    &-date {
        padding: 0 10rem;
        overflow: hidden;
        position: relative;
        
        &::after {
            position: absolute;
            left: 0;
            bottom: 1rem;
            width: 100%;
            height: 1.5rem;
            content: '';
            background: url(../assets/img/history-line.svg) bottom center no-repeat;
            background-size: contain;
        }

        .swiper-container {
            padding: 3rem 0 0;
        }

        &__carousel {


            &-item {
                text-align: center;
                transition: .5s all;

                div {
                    font-size: 1.2rem;
                    color: #bbb;
                    font-weight: 400;
                    margin-bottom: 0.5rem;
                }

                span {
                    display: inline-block;
                    width: 1.2rem;
                    height: 1.2rem;
                    border: .1rem solid #bbb;
                    position: relative;
                    border-radius: 50%;

                    &::after {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 50%;
                        height: 50%;
                        border-radius: 50%;
                        left: 50%;
                        top: 50%;
                        background: #bbb;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            .swiper-slide-active {
                transform: translateY(-3rem);

                div {
                    color: #e6c86a;
                    font-size: 1.5rem;
                    font-weight: 600;
                }

                span {
                    width: 2rem;
                    height: 2rem;
                    border: .2rem solid #FFE185;

                    &::after {
                        background: #BD9E3E;
                    }
                }
            }

            .swiper-slide-next, .swiper-slide-prev {
                transform: translateY(-2rem);

                div {
                    font-size: 1.4rem;
                    font-weight: 500;
                }

                span {
                    width: 1.6rem;
                    height: 1.6rem;
                }
                
            }
        }
    }

    &-carousel {
        max-width: 38.5rem;

        &__wrap {
            position: relative;
            margin-bottom: 6rem;

            .swiper-container {
                padding: 1rem;
            }
        }
        

        &__item {
            background: #FFFFFF;
            border: .2rem solid #FFE185;
            box-shadow: 0px 4px .5rem rgba(0, 0, 0, 0.15);
            border-radius: 1.6rem;
            padding: 2.4rem;
            font-size: 0.8rem;
        }

        &__img {
            margin-bottom: 1.2rem;

            img {
                width: 100%;
                border-radius: 1.6rem;
            }
        }

        &__desc {
            text-align: justify;
            line-height: 178%;
            color: #545454;
            margin-bottom: 2.4rem;
        }

        &__arrows {
            span {
                cursor: pointer;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                user-select: none;

                svg {
                    width: 6rem;
                    filter: drop-shadow(4px 7px 16px rgba(0, 0, 0, 0.25));

                    .fill {
                        transition: .3s all;
                    }

                    &:hover {
                        .fill {
                            fill: transparent;
                        }
                    }
                }

                &:first-child {
                    left: 12rem;
                }

                &:last-child {
                    right: 12rem;
                }
            }
        }

    }

    &-popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        background: rgba(255,225,133, 0.3);
        backdrop-filter: blur(20px);

        &__content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            border-radius: .7rem;
            max-width: 40rem;
            padding: 2.4rem;
            max-height: 75vh;
            overflow: auto;
        }

        &__title {
            font-size: 2rem;
            margin-bottom: 2rem;
        }

        &__text {
            text-align: justify;
            line-height: 178%;
            color: #545454;
            margin-bottom: 2rem;
        }

        &__close {
            position: absolute;
            right: 1.4rem;
            top: 1.4rem;
            cursor: pointer;
        }
    }

    &-info {
        display: flex;
        align-items: center;

        img {
            width: 40%;
            margin-right: 2.6rem;
        }

        &__text {
            text-align: justify;
            color: #545454;
            font-size: 1rem;
            line-height: 200%;
            padding-right: 8%;
        }
    }

}

@media (max-width: 768px), (max-width: 1080px) and (orientation: portrait) {
    .history {
        padding: 3rem 0;

        &__text {
            padding: 1.2rem;
            font-size: 1rem;
        }

        &-date {
            padding: 0 1rem;

            &__carousel {
                &-item {

                    div {
                        font-size: .9rem;
                    }

                    span {
                        width: .8rem;
                        height: .8rem;
                    }
                }

                .swiper-slide-active {
                    transform: translateY(-2.5rem);

                    div {
                        color: #e6c86a;
                        font-size: 1.2rem;
                    }

                    span {
                        width: 1.6rem;
                        height: 1.6rem;
                        border: .1rem solid #FFE185;
                    }
                }

                .swiper-slide-next, .swiper-slide-prev {
                    transform: translateY(-1.5rem);

                    div {
                        font-size: 1.2rem;
                    }

                    span {
                        width: 1.2rem;
                        height: 1.2rem;
                    }
                    
                }
            }
        }

        &-carousel {
            &__wrap {
                margin-bottom: 8rem;
            }

            &__item {
                padding: 1rem;
            }

            &__desc {
                margin-bottom: 1.5rem;
            }

            &__arrows {
                span {
                    transform: none;
                    top: auto;
                    bottom: -7rem;

                    svg {
                        width: 4rem;
                    }

                    &:first-child {
                        left: 6rem;
                    }

                    &:last-child {
                        right: 6rem;
                    }
                }
                
            }
        }

        &-popup {
            &__content {
                max-width: none;
                width: 95vw;
                padding: 2.4rem 1.2rem;
            }

            &__close {
                top: 0.5rem;
                right: 0.5rem;
            }
        }

        &-info {
            display: block;
            padding-right: 1rem;

            img {
                margin-right: 0;
                width: 100%;
            }

            &__text {
                padding-right: 0;
                padding-left: 1rem;
                margin-top: 2rem;
            }
        }
    }
}
</style>

<style scoped>
    .history-carousel__btn {
        width: 100%;
        background: rgba(196, 196, 196, 0.1);
        border: 3px solid #FFE185;
    }

    .history-carousel__btn:hover {
        background: #FFE185;
    }
</style>