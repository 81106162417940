<template>
    <section class="about" :class="{'about-page': aboutPage}">
        <h2 class="about__title" v-rellax="title">
            {{$t('about.title')}}
        </h2>
        <div class="container">
            <h2 class="about-page__title"  v-if="aboutPage">
                {{$t("about.pageTitle")}}
            </h2>
            <div class="about-content wow fadeInLeft" v-else data-wow-delay=".5s">
                <p>
                  {{$t("about.text")}}  
                </p>
                <router-link to="/about" class="btn">
                    {{$t("buttons.more")}}
                </router-link>
            </div>
            <div class="about-numbers" ref="aboutNumber">
                <div class="about-numbers__item">
                    <div>
                        <number
                            ref="number1"
                            :to="11"
                            :duration="3"
                            :animationPaused="true"
                            />
                    </div>
                    <p>
                        {{$t("about.numbers[0]")}}
                    </p>
                </div>
                <div class="about-numbers__item">
                    <div>
                        <number
                            ref="number2"
                            :to="40"
                            :duration="3"
                            :animationPaused="true"
                            />
                    </div>
                    <p>
                        {{$t("about.numbers[1]")}}
                    </p>
                </div>
                <div class="about-numbers__item">
                    <div>
                        <number
                            ref="number3"
                            :to="90"
                            :duration="3"
                            :animationPaused="true"
                            />
                    </div>
                    <p>
                       {{$t("about.numbers[2]")}}
                    </p>
                </div>
            </div>
            <div class="about-video">
				<div class="about-video__play" @click="playVideo">
					<img src="../assets/img/play.svg" alt="play">
				</div>
			</div>
        </div>
        <div class="about-sweets">
            <img src="../assets/img/cookies.png" alt="Cookies" class="about-sweets__cookies" 
            v-rellax="sweets" v-if="!aboutPage">
            <img src="../assets/img/cookies-about.png" alt="Cookies" class="about-sweets__cookies" 
            v-rellax="sweets" v-else>
            <img src="../assets/img/waffles.png" alt="Waffles" class="about-sweets__waffles" 
            v-rellax="sweets" v-if="!aboutPage">
            <img src="../assets/img/waffles-about.png" alt="Waffles" class="about-sweets__waffles" 
            v-rellax="sweets" v-else>
        </div>
        <div class="about__video" :class="{'about__video-show': isVideoShow}">
			<span class="about__video-close" @click="stopVideo">
				<img src="../assets/img/close.svg" alt="ico" class="img-fluid">
			</span>
			<video controls ref="aboutVideo" v-if="videos.length>0"> 
				<source :src="$hostname + videos[3].video" type="video/mp4">
					Ваш браузер не поддерживает HTML5 видео.
			</video>
		</div>
    </section>
</template>

<script>
export default {
    created() {
        window.addEventListener('scroll', this.startCounter)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.startCounter)
    },
    props: {
        aboutPage: Boolean
    },
    data() {
        return {
            title: {
                speed: 3
            },
            sweets: {
                speed: 4
            },
            isVideoShow: false,
        }
    },
    methods: {
        startCounter() {
            if(this.$refs.aboutNumber.getBoundingClientRect().top < window.innerHeight) {
                this.$refs.number1.play()
                this.$refs.number2.play()
                this.$refs.number3.play()
            }
        },

        stopVideo() {
			this.isVideoShow = false
			this.$refs.aboutVideo.pause()
		},

		playVideo() {
			this.isVideoShow = true
			this.$refs.aboutVideo.play()
		},
    },

    computed: {
        videos() {
            return this.$store.getters.getVideos
        },
    }
}
</script>

<style lang="less">
.about {
    height: 100vh;
    background: #F6F6F6;
    position: relative;

    &__title {
        color: #fff;
        font-size: 9.2rem;
        text-transform: uppercase;
        text-align: center;
        position: absolute;
        width: 100%;
        z-index: 0;
        margin-top: 20vh;
    }

    .container {
        position: relative;
        z-index: 1;
        background: url(../assets/img/about.png) center 1.5rem no-repeat;
        background-size: 90vh;
        height: 100%;
        display: flex;

    }

    &-numbers {
        position: absolute;
        width: 80%;
        bottom: 7.5rem;
        left: 10%;
        display: flex;
        justify-content: space-around;
        text-align: center;

        &__item {
            width: 15rem;

            div {
                text-shadow: 0px 6px 10px rgba(50, 50, 50, 0.1);
                color: #fff;
                font-size: 5rem;
                font-weight: bold;
            }

            p {
                color: #717171;
                font-size: 1.15rem;
                line-height: 133.3%;
            }
        }
    }

    &-content {
        align-self: center;
        max-width: 20.5rem;
        color: #5c5c5c;
        line-height: 222%;
        text-align: justify;
        
        p {
            margin-bottom: 1.2rem;
        }
    }

    &-sweets {
        img {
            position: absolute;
        }

        &__cookies {
            width: 20rem;
            left: 0;
            bottom: -30vh;
        }

        &__waffles {
            top: 20vh;
            right: 0;
            width: 26rem;
        }

        &__milk {
            width: 10rem;
            left: -4rem;
            top: 60vh;
        }
    }

    &__video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		clip-path: circle(2rem at 85vw 45vh);
		transition: 2s all;

		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 9999;
			object-fit: cover;
		}

		&-close {
			position: absolute;
			top: 4rem;
			right: 4rem;
			cursor: pointer;
			z-index: 19999;

			img {
				width: 1.5rem;
			}
		}

		&-show {
			clip-path: circle(150rem at 0 0);
            z-index: 9999;
		}
	}

	&-video {
		display: flex;
        justify-content: center;
        align-items: center;
		position: absolute;
		left: 85vw;
        transform: translate(-50%, -50%);
		top: 45vh;
		z-index: 12;

		p {
			font-size: 0.9rem;
			margin-right: 1.5rem;
		}

		&__play {
			height: 5rem;
			width: 5rem;
			display: flex;
            justify-content: center;
            align-items: center;
			position: relative;
			z-index: 1;
			cursor: pointer;
            border: .5rem solid rgba(167, 167, 167, 0.3);
            border-radius: 50%;

			&:hover {
				&::after {
					transform: rotate(90deg);
				}
			}


			img {
				width: 1rem;
			}

			&::after {
				content:'';
				height: 6rem;
				width: 6rem;
				border-radius: 50%;
				border: 1.5px dashed #DBDBDB;
				position: absolute;
				top: -1rem;
				left: -1rem;
				z-index: -1;
				transition: 1s all;
			}
		}
	}


    
    &-page {
        margin-top: 5rem;
        height: 110vh;

        .about__title {
            margin-top: 10vh;
        }

        &__title {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2.4rem;
            padding-top: 2rem;

            img {
                margin-left: 1.3rem;
                width: 9rem;
            }
        }

        .container {
            display: block;
            background-position: center 5rem;
        }

        .about-sweets__waffles {
            width: 14rem;
        }

        .about-sweets__cookies {
            z-index: 10;
        }
     }

    
}

@media (max-width: 768px), (max-width: 1080px) and (orientation: portrait) {
    .about {
        height: auto;
        padding-bottom: 5rem;

        &__title {
            font-size: 2.5rem;
            margin-top: 33vh;
        }

        .container {
            padding-top: 50vh;
            background-size: contain;
            flex-direction: column-reverse;
        }

        &-content {
            text-align: center;
        }

        &-numbers {
            position: static;
            width: auto;
            display: block;

            &__item {
                width: 100%;
                text-align: center;
                margin-bottom: 2rem;
            }
        }

        &-sweets img {
            width: 8rem;
        }

        &__video {
            &-close {
                right: 1rem;
                top: 1rem;
            }

            height: 100vh;      
            width: 100vw;
            background: #000;

            video {
                object-fit: contain;
            }
        }

        &-page {

            .container {
                padding-top: 0rem;

                background-position: center 10rem;
            }

            .about__title {
                margin-top: 10vh;
            }

            &__title {
                padding-top: 1rem;
                display: block;
                text-align: center;
                font-size: 2rem;
                margin-bottom: 75vw;

                img {
                    width: 7rem;
                    margin: .5rem auto;
                    display: block;
                    
                }
            }

            .about-sweets__waffles {
                width: 8rem;
            }

        }
    }
}

</style>