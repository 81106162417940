<template>
    <section class="main-catalog pattern">
        <div class="container">
            <h2 class="main-catalog__title">
                {{$t("catalog.title")}}
            </h2>
            <router-link to="/products" class="btn">
                {{$t("buttons.catalog")}}
            </router-link>
        </div>
        <div class="container container-block">
            <div class="main-catalog__category wow fadeInLeft" data-wow-delay=".5s" v-if="brands.length>0">
                <swiper ref="swiperCategory" :options="swiperOptions">
                    <swiper-slide v-for="(item,idx) in brands" :key="idx" @click.native="changeFilter(item.id)">
                        <product-item :image=item.photo :color=item.color
                        :name="getLangName(item)"/>
                    </swiper-slide>
                </swiper>
                <div class="arrow-left" @click="categoryPrev">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M26.9166 15.5834L11.0925 15.5834L18.0058 8.67006C18.5583 8.11756 18.5583 7.21089 18.0058 6.65839C17.4533 6.10589 16.5608 6.10589 16.0083 6.65839L6.67248 15.9942C6.11998 16.5467 6.11998 17.4392 6.67248 17.9917L16.0083 27.3276C16.5608 27.8801 17.4533 27.8801 18.0058 27.3276C18.5583 26.7751 18.5583 25.8826 18.0058 25.3301L11.0925 18.4167L26.9166 18.4167C27.6958 18.4167 28.3333 17.7792 28.3333 17.0001C28.3333 16.2209 27.6958 15.5834 26.9166 15.5834Z" fill="currentColor"/>
                    </svg>
                </div>
                <div class="arrow-right" @click="categoryNext">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.08335 18.4167L22.9075 18.4167L15.9942 25.33C15.4417 25.8825 15.4417 26.7892 15.9942 27.3417C16.5467 27.8942 17.4392 27.8942 17.9917 27.3417L27.3275 18.0058C27.88 17.4533 27.88 16.5608 27.3275 16.0083L17.9917 6.6725C17.4392 6.12 16.5467 6.12 15.9942 6.6725C15.4417 7.225 15.4417 8.1175 15.9942 8.67L22.9075 15.5833L7.08335 15.5833C6.30419 15.5833 5.66669 16.2208 5.66669 17C5.66669 17.7792 6.30419 18.4167 7.08335 18.4167Z" fill="currentColor"/>
                    </svg>
                </div>
            </div>
            <div class="main-catalog__product"  v-if="filteredProducts.length>0">
                <swiper ref="swiperProduct" :options="swiperOptions"
                :class="{centered: filteredProducts.length<slidesCount}">
                    <swiper-slide v-for="(item,idx) in filteredProducts" :key="idx">
                        <product-item :image=item.photo :color=item.color :hoverImage=item.hover_photo
                        :name=item.name :id=item.id :desc="getLangDesc(item)" />
                    </swiper-slide>
                </swiper>
                <div class="arrow-left" @click="productPrev" v-if="filteredProducts.length>slidesCount">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M26.9166 15.5834L11.0925 15.5834L18.0058 8.67006C18.5583 8.11756 18.5583 7.21089 18.0058 6.65839C17.4533 6.10589 16.5608 6.10589 16.0083 6.65839L6.67248 15.9942C6.11998 16.5467 6.11998 17.4392 6.67248 17.9917L16.0083 27.3276C16.5608 27.8801 17.4533 27.8801 18.0058 27.3276C18.5583 26.7751 18.5583 25.8826 18.0058 25.3301L11.0925 18.4167L26.9166 18.4167C27.6958 18.4167 28.3333 17.7792 28.3333 17.0001C28.3333 16.2209 27.6958 15.5834 26.9166 15.5834Z" fill="currentColor"/>
                    </svg>
                </div>
                <div class="arrow-right" @click="productNext" v-if="filteredProducts.length>slidesCount">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.08335 18.4167L22.9075 18.4167L15.9942 25.33C15.4417 25.8825 15.4417 26.7892 15.9942 27.3417C16.5467 27.8942 17.4392 27.8942 17.9917 27.3417L27.3275 18.0058C27.88 17.4533 27.88 16.5608 27.3275 16.0083L17.9917 6.6725C17.4392 6.12 16.5467 6.12 15.9942 6.6725C15.4417 7.225 15.4417 8.1175 15.9942 8.67L22.9075 15.5833L7.08335 15.5833C6.30419 15.5833 5.66669 16.2208 5.66669 17C5.66669 17.7792 6.30419 18.4167 7.08335 18.4167Z" fill="currentColor"/>
                    </svg>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ProductItem from './ProductItem.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'


export default {
    components: {
        Swiper,		  
		SwiperSlide,
        ProductItem
    }, 

    mounted() {
        let width = window.innerWidth

        if(width < 550) {
            this.slidesCount = 2
        } else if(width >= 700 && width < 1000) {
            this.slidesCount = 3
        }  else if(width >= 1100 && width < 1200) {
            this.slidesCount = 4
        } else {
            this.slidesCount = 6
        }
    },

    data() {
        return {
            image: 'miller.png',
            color: '#D4A378',
            name: 'Miller Captain',
            desc: 'конфеты вафельнные',
            link: true,
            id: 1,
            filterId: null,
            slidesCount: 0,
            swiperOptions: {
                autoWidth: true,
                speed: 500,
                allowTouchMove: false,
                breakpoints: {
                    0: {
                        allowTouchMove: true,
                        slidesPerView: "2",
                    },

                    700: {
                        slidesPerView: "3",
                        allowTouchMove: true,
                    },

                    1100: {
                        slidesPerView: "4",
                    },

                    1200: {
                        slidesPerView: "6",
                    },
                }
            },
        }
    },

    methods: {
        productNext() {
            this.$refs.swiperProduct.$swiper.slideNext()
        },
        productPrev() {
            this.$refs.swiperProduct.$swiper.slidePrev()
        },
        categoryNext() {
            this.$refs.swiperCategory.$swiper.slideNext()
        },
        categoryPrev() {
            this.$refs.swiperCategory.$swiper.slidePrev()
        },
        changeFilter(id) {
            this.filterId = id
        },
        getLangName(item) {
            if(this.$root.$i18n.locale == 'uz') {
                return item.name_uz
            } else if (this.$root.$i18n.locale == 'en') {
                return item.name_en
            } else {
                return item.name_ru
            }
        },
        getLangDesc(item) {
            if(this.$root.$i18n.locale == 'uz') {
                return item.l_name_uz
            } else if (this.$root.$i18n.locale == 'en') {
                return item.l_name_en
            } else {
                return item.l_name_ru
            }
        },

    },
    computed: {
        brands() {
            return this.$store.getters.getBrands
        },

        products() {
            return this.$store.getters.getProducts
        },

        filteredProducts() {
            return this.products.filter(item => item.brand_id === this.filterId)
        }
    },
}
</script>

<style lang="less">
.main-catalog {
    padding: 4rem 0;
    min-height: 26rem;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;

        &-block {
            display: block;
        }
    }

    &__title {
        color: var(--yellow);
        font-size: 3.2rem;
        font-weight: 700;
    }
    

    &__category, &__product {
        position: relative;


        .arrow-left, .arrow-right {
            cursor: pointer;
            position: absolute;
            width: 4.8rem;
            height: 4.8rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 1px solid #A7A7A7;
            color: #A7A7A7;
            transition: .3s all;
            top: 55%;
            transform: translateY(-50%);
            z-index: 10;

            &:hover {
                background: #fff;
                border: 2px solid #c98801;
                color: #c98801;
            }
        }

        .arrow-left {
            left: 0rem;
        }

        .arrow-right {
            right: 0rem;
        }

        .swiper-slide {
            display: flex;
            justify-content: center;
        }
    }

    &__category {
        .product-item {
            cursor: pointer;
        }
    }

    &__product {
        .arrow-left, .arrow-right {
            top: 65%;
        }

        .centered .swiper-wrapper{
            justify-content: center;
        }
    }


    .swiper-container-free-mode > .swiper-wrapper {
        transition-timing-function : linear !important;
    }

}

@media (max-width: 768px) {
    .main-catalog {
        .arrow-left, .arrow-right {
            display: none;
        }

        .btn {
            position: absolute;
            bottom: 2rem;
            width: 90%;
            left: 50%;
            transform: translateX(-50%);
        }
        
        &__title {
            font-size: 2rem;
        }
    }
}
</style>



<style scoped>
    .main-catalog::before {
        background: url(../assets/img/pattern-gray.png) center center no-repeat;
        background-size: contain;
    }

    @media (max-width: 768px) {
        .main-catalog::before {
            content: none;
        }
    }
</style>