<template>
    <section class="page404">
        <div class="page404__content">
            <div>
                {{$t('page404.title')}}
            </div>
            <router-link to="/" class="btn">
               {{$t('page404.btn')}}
            </router-link>
            <img src="../assets/img/cookies.png" alt="cookies">
        </div>
    </section>
</template>

<script>
export default {
    mounted() {
        this.$options.metaInfo.title = this.$i18n.t('meta.main.title')
        this.$options.metaInfo.meta[0].content = this.$i18n.t('meta.main.description')
        this.$options.metaInfo.meta[1].content = this.$i18n.t('meta.main.keywords')
    },
    metaInfo: {
        title: '',
        meta: [
            { name: 'description', content: '' },
            { name: 'keywords', content: '' },
        ]
    },
}
</script>

<style lang="less" >
    .page404 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
        background: #fffdf9;
        position: relative;

        &__content {
            text-align: center;

            div {
                font-size: 3rem;
                font-weight: 700;
                margin-bottom: 2rem;
            }

            img {
                position: absolute;
                right: 10vw;
                pointer-events: none;
                width: 20rem;
            }
        }
    }

    @media (max-width: 768px), (max-width: 1080px) and (orientation: portrait) {
        .page404 {
            &__content {
                div {
                    font-size: 2rem;
                }

                img {
                    right: 0;
                    width: 10rem;
                    bottom: 0;
                }
            }
        }
    }
</style>