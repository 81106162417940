<template>
    <div v-if="products.length > 0">
        <section class="product pattern" :style="{background: color}">
            <div class="container">
                <div class="product-content">
                    <div class="product__title">
                        {{name}}
                    </div>
                    <p class="product__desc">
                        {{desc}}
                    </p>
                </div>
                <div class="product-btns">
                    <a href="https://uz1420968845azcq.trustpass.alibaba.com/" target="_blank" rel="nofollow"
                    class="btn">{{$t('buttons.alibaba')}}</a>
                    <a href="#" class="btn" @click.prevent="openFeedback">{{$t('buttons.contact')}}</a>
                </div>
                <img :src="$hostname + image" alt="" class="product__img">
            </div>
        </section>
        <div class="product-bg" :style="{'background-image': `url(${$hostname + bg})`}">
        </div>
        <app-package :product=product :comp=comp :packageInfo=packageInfo :packageImg=packageImg 
        :packageIcos=packageIcos @openFeedback=openFeedback />
    </div>
</template>

<script>
import AppPackage from '../components/Package.vue'

export default {
    components: {
        AppPackage
    },
    mounted() {
        this.$options.metaInfo.title = this.$i18n.t('meta.products.title')
        this.setProduct()              
    },
    metaInfo: {
        title: '',
        meta: [
            { name: 'description', content: '' },
            { name: 'keywords', content: '' },
        ]
    },
    data() {
        return {
            id: 0,
            name: '',
            desc: '',
            image: '',
            color: '',
            bg: '',
            comp: '',
            product: null,
            packageImg: '',
            packageInfo: {
                name: '',
                weight: '',
                count: '',
                time: '',
                size: '',
            },
            packageIcos: ['package1.svg','package2.svg','package3.svg','package4.svg','package5.svg',],         
        }
    },
    methods: {
        openFeedback() {
            this.$emit('openFeedback')
        },

        setProduct() {
            if(this.products.length > 0) {
                this.id = this.$route.params.id
                let product = this.products.find(item => item.id == this.id)
                if(product) {
                    this.image = product.photo
                    this.color = product.color
                    this.bg = product.back_photo
                    this.name = product.name
                    this.packageImg = product.box_photo
                    this.packageInfo.name = product.type
                    this.packageInfo.weight = product.weight
                    this.packageInfo.count = product.number
                    this.packageInfo.time = product.shelflife
                    this.packageInfo.size = product.size
                    this.product = product.cat_id
                    if(this.$root.$i18n.locale == 'uz') {
                        this.desc = product.l_description_uz
                        this.comp = product.composition_uz
                    }  else if(this.$root.$i18n.locale == 'en') {
                        this.desc = product.l_description_en
                        this.comp = product.composition_en
                    }  else {
                        this.desc = product.l_description_ru
                        this.comp = product.composition_ru
                    }

                    this.$options.metaInfo.title = 'Deya | ' + this.name
                    this.$options.metaInfo.meta[0].content = this.$i18n.t('meta.products.description')
                    this.$options.metaInfo.meta[1].content = this.$i18n.t('meta.products.keywords')

                } else {
                    this.$router.push('/404')
                }

            }
        },
    },
    computed: {
        products() {
            return this.$store.getters.getProducts
        },
    },

    watch: {
        products() {
            this.setProduct()
        },

        $route() {
            this.setProduct()
        }
    }
}
</script>

<style lang="less">
.product {
    padding: 10rem 0 15rem;
    color: rgba(255, 255, 255, .95);


    &__title {
        font-size: 3rem;
        font-weight: 700;
        margin-bottom: 2rem;
        max-width: 40rem;
    }

    &__desc {
        font-size: 1rem;
        line-height: 200%;
        max-width: 25rem;
    }

    &-btns {
        display: flex;
        flex-direction: column;
        float: right;
        transform: translateY(-12vh);

        .btn {
            margin-bottom: 1rem;

            &:hover {
                color: #555;
                background: #fff;
                border-color: #fff;
            }
        }
    }

    &__img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 30rem;
        height: 30rem;
        object-fit: contain;
        z-index: 3;
        filter: drop-shadow(18px 10px 16px rgba(0, 0, 0, 0.25));
    }

    &-bg {
        background-image: '';
        height: 50vh;
        background-repeat: repeat;
        background-size: 100% auto;
        animation: animatedBackground 400s linear infinite;
        position: relative;
        z-index: 2;
        margin-top: -15rem;
    }
}


@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 -10000px;
  }
}

@media (max-width: 768px), (max-width: 1080px) and (orientation: portrait) {
    .product {
        height: auto;
        padding-bottom: 0;

        &__img {
            margin-top: 2rem;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }

        &-btns {
            position: absolute;
            bottom: -45vh;
            left: 50%;
            transform: translateX(-50%);
            float: none;
            z-index: 5;

            .btn {
                position: relative;
                z-index: 2;
            }
        }

        &__title {
            font-size: 2.2rem;
        }

        &__img {
            width: 20rem;
            height: 20rem;
        }

        &-bg {
            margin-top: -10rem;
            height: 40vh;
            background-size: 240vw auto;
        }
    }
}

</style>

<style scoped>
.product::before {
    content: none;
}

.product::after {
    top: 5rem;
    bottom: auto;
    transform: translate(50%, -50%);
}

@media (max-width: 768px) {
    .product {
        overflow: visible;
    }

    .product::after {
        content: none;
    }
}
</style>