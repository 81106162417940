import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {i18n} from './i18n'
import './App.less'
import VueScrollactive from 'vue-scrollactive';
import VueRellax from 'vue-rellax'
import VueNumber from 'vue-number-animation'
import VueMeta from 'vue-meta'
import browserDetect from "vue-browser-detect-plugin";


Vue.config.productionTip = false

Vue.use(VueRellax)
Vue.use(VueNumber)
Vue.use(VueScrollactive)
Vue.use(VueMeta)
Vue.use(browserDetect);

Vue.prototype.$hostname = 'https://deya.uz'

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
