import Vue from 'vue'
import Vuex from 'vuex'
import {newsAPI} from '../api'
import {catalogAPI} from '../api'
import {videosAPI} from '../api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    news: [],
    brands: [],
    categories: [],
    products: [],
    videos: [],
  },
  getters: {
    getNews(state) {
      return state.news
    },
    getBrands(state) {
      return state.brands
    },
    getCategories(state) {
      return state.categories
    },
    getProducts(state) {
      return state.products
    }, 
    getVideos(state) {
      return state.videos
    }, 
  },
  mutations: {
    SET_NEWS(state, payload) {
      state.news = payload
    },
    SET_VIDEOS(state, payload) {
      state.videos = payload
    },
    SET_CATALOG(state, payload) {
      state.brands = payload[0]
      state.categories = payload[1]
      state.products = payload[2]
    }
  },
  actions: {
    async loadNews({commit}) {
      const news = await newsAPI().then(response => response.data.reports.reverse())
      commit('SET_NEWS', news)
    },
    async loadVideos({commit}) {
      const video = await videosAPI().then(response => response.data.video)
      commit('SET_VIDEOS', video)
    },
    async loadCatalog({commit}) {
      const brands = await catalogAPI().then(response => response.data.brands)
      const categories = await catalogAPI().then(response => response.data.categories)
      const products = await catalogAPI().then(response => response.data.products)
      commit('SET_CATALOG', [brands, categories, products])
    }
  },
})
