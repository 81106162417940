<template>
    <div>
        <transition name="fade">
            <div class="feedback" v-if="isOpen" @click.self="closeFeedback">
                <div class="feedback-content">
                    <div class="feedback__close" @click="closeFeedback">
                        <img src="../assets/img/feedback-close.svg" alt="ico">
                    </div>
                    <div v-if="!sended">
                        <div class="feedback__title">
                            {{$t('mainFeedback.title')}}
                        </div>
                        <div class="feedback__text">
                            {{$t('form.text')}}
                        </div>
                        <form action="#" class="feedback__form" @submit.prevent="sendMessage">
                            <label for="name">{{$t("form.name")}}*</label>
                            <input type="text" id="name" required v-model="name">
                            <label for="tel">{{$t("form.tel")}}*</label>
                            <input type="tel" id="tel" required  v-model="tel">
                            <label for="message">{{$t("form.comment")}}</label>
                            <input type="text" id="message" required v-model="message">
                            <button type="submit" class="btn" :disabled="btnDisabled">
                                {{$t("buttons.feedbackSend")}}
                            </button>
                        </form>
                    </div>
                    <div class="feedback-done" v-else>
                        <img src="../assets/img/done.svg" alt="ico">
                        <div class="feedback__title">
                            {{$t('form.done.title')}}
                        </div>
                        <div class="feedback__text">
                            {{$t('form.done.text')}}
                        </div>
                        <a href="#" class="btn" @click.prevent="closeFeedback">
                            {{$t('form.done.btn')}}
                        </a>
                    </div>
                </div>
            </div>
        </transition>
        <div class="tel-popup" @click="isOpen = true">
            <img src="../assets/img/tel-popup.svg" alt="ico">
        </div>
        <a href="mailto:info@deya.uz" class="tel-popup mail-popup">
            <img src="../assets/img/mail-popup.svg" alt="ico">
        </a>
    </div>
</template>

<script>
import {feedbackAPI} from '../api'

export default {
    props: {
        feedbackOpen: Boolean
    },
    created() {
        this.detectProduct()
    },
    data() {
        return {
            isOpen: false,
            name: '',
            tel: '',
            message: '',
            productId: '',
            sended: false,
            btnDisabled: false,
        }
    },
    methods: {
        closeFeedback() {
            this.isOpen = false
            this.$emit('closeFeedback')
            this.sended = false
            this.btnDisabled = false
        },
        async sendMessage() {
           this.btnDisabled = true
           await feedbackAPI(this.name, '', this.tel, '', this.message, this.productId)
           .then(response => {
               if(response.status == 200 || response.status == 201) {
                    this.name = ''
                    this.tel = ''
                    this.message = ''
                    this.sended = true
               }
           })
       },
       detectProduct() {
           if(this.$route.name == 'ProductSingle') {
               this.productId = this.$route.params.id
           } else {
                this.productId = ''
           }
       }
    },
    watch: {
        feedbackOpen() {
            this.isOpen = this.feedbackOpen
        },

        $route() {
            this.detectProduct()
        }
    }
}
</script>

<style lang="less">
.feedback {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(49, 40, 75, 0.6);
    backdrop-filter: blur(20px);

    &-content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 2.5rem;
        border-radius: 1.5rem;
        background: #31284B;
        border: .2rem solid #FFE185;
        color: #fff;
        width: 25.2rem;
    }

    &__close {
        position: absolute;
        cursor: pointer;
        top: -1.5rem;
        right: -1.5rem;

        img {
            width: 1.5rem;
        }
    }

    &__title {
        font-size: 1.5rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 1rem;
    }

    &__text {
        font-size: 0.8rem;
        line-height: 100%;
        font-weight: 400;
        color: rgba(255,255,255, .8);
        margin-bottom: 1rem;
        text-align: center;
    }

    &__form {
        label {
            display: block;
            color: #D9A577;
            font-size: 1rem;
            margin-bottom: 0.4rem;
            padding-left: 1rem;
        }

        input {
            background: #453A63;
            display: block;
            width: 100%;
            border: .15em solid transparent;
            outline: none;
            color: #fff;
            padding: .85rem 1rem;
            border-radius: 5rem;
            margin-bottom: 1.2rem;
            transition: .3s all;

            &:focus {
                border-color: var(--yellow);
            }
        }

        .btn {
            width: 100%;
            color: #575454;
            margin-top: 1.5rem;
        }
    }

    &-done {
        text-align: center;

        img {
            width: 4rem;
            margin-bottom: 1.5rem;
        }

        .btn {
            width: 100%;
            color: #575454;
            margin-top: 1.5rem;
        }
    }
}


.tel-popup {
	width: 4.4rem;
	height: 4.4rem;
	cursor: pointer;
	position: fixed;
	right: 5rem;
    bottom: 14.5rem;
	z-index: 99;
	cursor: pointer;
	border-radius: 50%;
	animation: pulse-yellow 2s infinite;
	box-shadow: 0 0 0 0  rgba(255,225,133, .7);

	img {
		width: 4.4rem;
		height: 4.4rem;
	}
}

.mail-popup {
    bottom: 7.5rem;
}

@keyframes pulse-yellow {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0  rgba(255,225,133, .7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 1rem rgba(255, 121, 63, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
	}
}

@media (max-width: 768px), (max-width: 1080px) and (orientation: portrait)  {
    .feedback { 
       
       &-content {
           padding: 2rem 1.5rem;
            width: 90%;
       }

       &__close {
           right: -.5rem;
       }
    }

    .tel-popup {
        right: 1rem;
        bottom: 7rem;
        width: 3.5rem;
        height: 3.5rem;

        img {
            width: 3.5rem;
            height: 3.5rem;
        }
    }

    .mail-popup {
        bottom: 2.5rem;
    }
}
</style>