<template>
    <div>
        <page-video @changeHeader=changeHeader :notMain=true :notVideo=true
        :title=title :text="$t('news.single')" :image=image class="page-video__news-single"/>
        <section class="news-single">
            <div class="news-single__container">
                <h3 class="news-single__title">
                    {{title}}
                </h3>
                <div class="news-single__text" v-html="text">
                </div>
            </div>
            <div class="about-sweets">
                <img src="../assets/img/cookies.png" alt="Cookies" class="about-sweets__cookies" 
                v-rellax="sweets">
                <img src="../assets/img/milk.png" alt="Cookies" class="about-sweets__milk" 
                v-rellax="sweets">
                <img src="../assets/img/waffles-about.png" alt="Waffles" class="about-sweets__waffles" 
                v-rellax="sweets">
            </div>
        </section>
        <main-news/>
    </div>
</template>

<script>
import PageVideo from '../components/PageVideo.vue'
import MainNews from '../components/MainNews.vue'

export default {
     components: {
        PageVideo,
        MainNews
    },
    mounted() {
        this.$options.metaInfo.title = this.$i18n.t('meta.news.title')
        this.setNews()
    },
    metaInfo: {
        title: '',
        meta: [
            { name: 'description', content: '' },
            { name: 'keywords', content: '' },
        ]
    },
    data() {
        return {
            sweets: {
                speed: 4
            },
            title: 'Новый завод',
            text: 'TM Deya — пользуется признанием на рынке Узбекистана и в странах ближнего зарубежья за высокое качество и  доступные цены. TM Deya — пользуется признанием на рынке Узбекистана и в странах ближнего зарубежья за высокое качество и  доступные цены.TM Deya — пользуется признанием на рынке Узбекистана и в странах ближнего зарубежья за высокое качество и  доступные цены. TM Deya — пользуется признанием на рынке Узбекистана и в странах ближнего зарубежья за высокое качество и  доступные цены.TM Deya — пользуется признанием на рынке Узбекистана и в странах ближнего зарубежья за высокое качество и  доступные цены. TM Deya — пользуется признанием на рынке Узбекистана и в странах ближнего зарубежья за высокое качество и  доступные цены.',
            image: 'gallery.jpg',
            id: 0
        }
    },
    methods: {
        changeHeader(data) {
            this.$emit('changeHeader', data)
        },
        setNews() {
            if(this.news.length > 0) {
                this.id = this.$route.params.id
                let newsSingle = this.news.find(item => item.id == this.id) 
                if(newsSingle) {
                    this.image = newsSingle.photo
                    if(this.$root.$i18n.locale == 'uz') {
                        this.title = newsSingle.name_uz
                        this.text = newsSingle.description_uz
                    }  else if(this.$root.$i18n.locale == 'en') {
                        this.title = newsSingle.name_en
                        this.text = newsSingle.description_en
                    } else {
                        this.title = newsSingle.name_ru
                        this.text = newsSingle.description_ru
                    }
                } else {
                    this.$router.push('/404')
                }

                this.$options.metaInfo.title = 'Deya | ' + this.title
                this.$options.metaInfo.meta[0].content = this.$i18n.t('meta.news.description')
                this.$options.metaInfo.meta[1].content = this.$i18n.t('meta.news.keywords')

            }
        }
    },
    computed: {
        news() {
            return this.$store.getters.getNews
        }
    },
    watch: {
        news() {
            this.setNews() 
        },
        $route() {
            this.setNews()
        }
    }
}
</script>

<style lang="less">
    .news-single {
        padding: 4rem 0 6rem;
        background: #fffdf9;
        position: relative;

        &__container {
            max-width: 50rem;
            margin: 0 auto;
            padding: 0 1rem;
        }

        &__title {
            font-size: 2rem;
            text-align: center;
            margin-bottom: 3.5rem;
        }

        &__text {
            color: #545454;
            font-size: 1rem;
            line-height: 200%;
            text-align: justify;
        }

        .about-sweets img {
            width: 13rem;

        }

        .about-sweets__milk {
            top: 30vh;
        }

        .about-sweets__waffles {
            top: 60vh;
        }
    }

    .page-video__news-single .page-video__title{
        font-size: 2.5rem;
    }

    @media (max-width: 768px), (max-width: 1080px) and (orientation: portrait) {
        .news-single {
            padding: 3rem 0;

            &__title {
                margin-bottom: 2rem;
            }

            .about-sweets img {
                display: none;
            }
        }
    }
</style>