<template>
    <section class="gallery">
        <div class="gallery-render">
            <img src="../assets/img/gallery.jpg" alt="Gallery">
            <svg width="1920" height="1078" viewBox="0 0 1920 1078" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path @mouseenter="showInfo($t('gallery.render[1].title'), $t('gallery.render[1].text'), $event)" 
                @mouseleave="isInfoShow=false"
                d="M465.5 863.393V657.5L870.874 633L1291 685.202V872.93L870.874 891L757.57 888.49L669.835 863.393H514.919H465.5Z" fill="#DF6363" fill-opacity="0.7" stroke="#FF5555" stroke-width="3"/>
                <path @mouseenter="showInfo($t('gallery.render[0].title'), $t('gallery.render[0].text'), $event)" 
                @mouseleave="isInfoShow=false"
                d="M465.5 863.5V657.5L171 675V855L465.5 863.5Z" fill="#DF6363" fill-opacity="0.7" stroke="#FF5555" stroke-width="3"/>
                <path @mouseenter="showInfo($t('gallery.render[2].title'), $t('gallery.render[2].text'), $event)" 
                @mouseleave="isInfoShow=false"
                d="M1695 759.376L1291 722V873L1697 859.545L1695 759.376Z" fill="#DF6363" fill-opacity="0.7" stroke="#FF5555" stroke-width="3"/>
                <path @mouseenter="showInfo($t('gallery.render[3].title'), $t('gallery.render[3].text'), $event)" 
                @mouseleave="isInfoShow=false"
                d="M1696.5 736L1504 741.522L1695 759.595L1696.5 860L1793 857.49V747.547L1696.5 736Z" fill="#DF6363" fill-opacity="0.7" stroke="#FF5555" stroke-width="3"/>
            </svg>
            <div class="gallery-render__info" v-if="isInfoShow"
            :style="{top: infoPosition.top + 'px', left: infoPosition.left + 'px'}">
                <div>
                    {{infoTitle}}
                </div>
                <p v-html="infoText">     
                </p>
            </div>                
        </div>
        <div class="container">
            <swiper ref="swiperGallery" :options="swiperOptions">
                <swiper-slide v-for="(item, idx) in carousel" :key="idx">
                    <div class="gallery-carousel__item" v-if="!item.double">
                        <img :src="require('../assets/img/' + item.image)" alt="gallery-item">
                        <span @click="zoomOpen(item.image)">
                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M38 10V38H10V10H38ZM38 6H10C7.8 6 6 7.8 6 10V38C6 40.2 7.8 42 10 42H38C40.2 42 42 40.2 42 38V10C42 7.8 40.2 6 38 6ZM28.28 23.72L22.28 31.46L18 26.28L12 34H36L28.28 23.72Z" fill="white"/>
                            </svg>
                        </span>
                    </div>
                    <div class="gallery-carousel__double" v-else>
                        <div class="gallery-carousel__item">
                            <img :src="require('../assets/img/' + item.image[0])" alt="gallery-item">
                            <span @click="zoomOpen(item.image[0])">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M38 10V38H10V10H38ZM38 6H10C7.8 6 6 7.8 6 10V38C6 40.2 7.8 42 10 42H38C40.2 42 42 40.2 42 38V10C42 7.8 40.2 6 38 6ZM28.28 23.72L22.28 31.46L18 26.28L12 34H36L28.28 23.72Z" fill="white"/>
                                </svg>
                            </span>
                        </div>
                        <div class="gallery-carousel__item">
                            <img :src="require('../assets/img/' + item.image[1])" alt="gallery-item">
                            <span @click="zoomOpen(item.image[1])">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M38 10V38H10V10H38ZM38 6H10C7.8 6 6 7.8 6 10V38C6 40.2 7.8 42 10 42H38C40.2 42 42 40.2 42 38V10C42 7.8 40.2 6 38 6ZM28.28 23.72L22.28 31.46L18 26.28L12 34H36L28.28 23.72Z" fill="white"/>
                                </svg>
                            </span>
                        </div>
                    </div>
                </swiper-slide>
            </swiper> 
            <div class="gallery-carousel__arrows">
                <span @click="prevSlide">
                    <arrow-left />
                </span>
                <span @click="nextSlide">
                    <arrow-right />
                </span>
            </div>
        </div>
        <div class="gallery-wrap wow fadeInUp" data-wow-delay=".5s"> 
            <div class="container">
                <div class="gallery__title">
                    {{$t('gallery.title')}}
                </div>
                <p class="gallery__text">
                    {{$t('gallery.text')}}
                </p>
            </div>
        </div>
        <transition name="fade">
            <div class="gallery-zoom" v-if="showZoom" @click.self="showZoom = false">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" @click="showZoom = false">
                    <path d="M11.5237 10.6259L19.8274 18.9295L18.9125 19.8445L10.6088 11.5408L1.92532 20.2243L0.975834 19.2748L9.6593 10.5914L1.68363 2.61569L2.59859 1.70073L10.5743 9.67639L19.275 0.975667L20.2245 1.92515L11.5237 10.6259Z" fill="#fff"></path>
                </svg>
                <img :src="require('../assets/img/' + zoomImg )" alt="img">
            </div>
        </transition>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import ArrowLeft from './ArrowLeft.vue'
import ArrowRight from './ArrowRight.vue'

export default {
    components: {
        Swiper,		  
		SwiperSlide,
        ArrowLeft, ArrowRight
    },
    created() {
        setInterval(() => {
            if(this.$refs.swiperGallery) {
                this.$refs.swiperGallery.$swiper.slideNext()
            }		
        }, 500)
    },
    
    data() {
        return {
            isInfoShow: false,
            infoTitle: '',
            infoText: '',
            infoPosition: {
                top: 0,
                left: 0
            },
            carousel: [
                {image: 'gallery2.jpg', double: false},
                {image: ['gallery1.jpg', 'gallery4.jpg'], double: true},
                {image: 'gallery3.jpg', double: false},
                {image: 'gallery5.jpg', double: false},
                {image: 'gallery6.jpg', double: false},
                {image: 'gallery7.jpg', double: false},
                {image: 'gallery8.jpg', double: false},
                {image: 'gallery9.jpg', double: false},
                {image: 'gallery10.jpg', double: false},
                {image: 'gallery11.jpg', double: false},
                {image: 'gallery12.jpg', double: false},
                {image: 'gallery13.jpg', double: false},
                {image: 'gallery14.jpg', double: false},
            ],
            showZoom: false,
            zoomImg: '',
            swiperOptions: {
                speed: 2500,
                freeMode: true,
                loop: true,
                breakpoints: {
                    0: {
                        spaceBetween: 20,
                        slidesPerView: "1.3",
                    },

                    550: {
                        slidesPerView: "2",
                        speceBetween: 20,
                    },

                    900: {
                        spaceBetween: 30,
                        slidesPerView: "3",
                    },
                }
            },
        }
    },
    methods: {
        showInfo(title, text, event) {
            this.isInfoShow = true
            this.infoTitle = title
            this.infoText = text

            if(window.innerWidth > 768) {
                let top = event.target.getBoundingClientRect().top;
                let left = event.target.getBoundingClientRect().left;
                this.infoPosition.top = top
                this.infoPosition.left = left
            } else {
                this.infoPosition.top = 120
                this.infoPosition.left = 20
            }
         
        },
        zoomOpen(src) {
            this.showZoom = true
            this.zoomImg = src
        },
        prevSlide() {
            this.$refs.swiperGallery.$swiper.slidePrev()
        },

        nextSlide() {
            this.$refs.swiperGallery.$swiper.slideNext()
        },
    },
}
</script>

<style lang="less">
.gallery {
    position: relative;
    overflow: hidden;

    &::before, &::after {
        content: '';
        display: inline-block;
        width: 55rem;
        height: 55rem;
        background: url(../assets/img/pattern-gray.png) center center no-repeat;
        background-size: contain;
        pointer-events: none;
        position: absolute;
        z-index: 0;
    }

    &::before {
        top: 100vh;
        left: 0;
        transform: translate(-50%, -50%);
    }

    &::after {
        right: 0;
        bottom: 20rem;
        transform: translate(50%, 50%);
    }

    &-render {
        position: relative;
        margin-bottom: 4.5rem;
        z-index: 1;

        img {
            width: 100%;
        }

        svg {
            position: absolute;
            left: 0;
            width: 100%;
            height: auto;
            top: 0;

            path {
                opacity: 0;
                transition: .3s all;
                animation: svgPulse 2s infinite linear;

                &:hover {
                    opacity: 1;
                    animation: none;
                }
            }
        }

        &__info {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(-30%);
            border: .2rem solid #FFE185;
            box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.15);
            backdrop-filter: blur(10px);
            background: rgba(185, 185, 185, 0.5);
            border-radius: 1.6rem;
            padding: 1.5rem;
            color: #fff;
            width: 21rem;
            pointer-events: none;

            div {
                font-weight: bold;
                font-size: 1rem;
                text-align: center;
                margin-bottom: 1rem;
            }

            p {
                line-height: 178%;
            }
        }
    }

 
    &-carousel {
       
        
        &__arrows {
            display: flex;
            justify-content: flex-end;
            margin: 1rem 0;
            user-select: none;

            span {
                cursor: pointer;

                &:first-child {
                    margin-right: 1rem;
                }

                svg {
                    width: 2.5rem;
                    filter: drop-shadow(4px 7px 16px rgba(0, 0, 0, 0.25));

                    .fill {
                        transition: .3s all;
                    }

                    &:hover {
                        .fill {
                            fill: transparent;
                        }
                    }
                }
            }
        }

        &__item {
            overflow: hidden;
            border-radius: 1.6rem;         
            position: relative;
            height: 25rem;

            &:hover {
                span {
                    opacity: 1;
                }
            }

            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
                border-radius: 1.6rem;
                 border: .3rem solid var(--yellow);
            }

            span {
                position: absolute;
                width: 4.3rem;
                height: 4.3rem;
                top: 50%;
                left: 50%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background: rgba(232, 221, 192, 0.5);
                backdrop-filter: blur(8px);
                border-radius: .4rem;
                opacity: 0;
                transition: .3s all;

                svg {
                    width: 2.4rem;
                }
            }
        }

        &__double {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 25rem;

            .gallery-carousel__item {
                height: 12rem;
            }
        }
    }

    &__title {
        font-size: 2.4rem;
        font-weight: 600;
        margin-bottom: 2rem;
    }

    &-wrap {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 5rem;

        &::after {
            content: '';
            background: url('../assets/img/gallery-info.png') center center no-repeat;
            background-size: contain;
            position: absolute;
            right: 0;
            width: 40%;
            height: 30rem;
            top: 50%;
            transform: translateY(-50%);
        }

        .container {
            padding-right: 43%;
        }
    }

    &__text {
        text-align: justify;
        color: #545454;
        font-size: 1rem;
        line-height: 200%;
    }

    &-zoom {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background: rgba(0, 0, 0, .7);

        img {
            width: 70vw;
            height: 80vh;
            position: absolute;
            object-fit: contain;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        svg {
            width: 1.5rem;
            height: 1.5rem;
            position: absolute;
            right: 5rem;
            top: 2rem;
            cursor: pointer;
        }
    }
}

@keyframes svgPulse {
    0% { opacity: 0;}
    50% { opacity: .3;}
    100% { opacity: 0;}
}

@media (max-width: 768px), (max-width: 1080px) and (orientation: portrait) {
    .gallery {

        &__title {
            font-size: 2rem;
            text-align: center;
            margin-bottom: 1.7rem;
        }

        &-render {
            max-width: 100vw;
            overflow: auto;
            margin-bottom: 3rem;

            img {
                height: 40rem;
                width: auto;
                max-width: none;
            }

            svg {
                height: 40rem;
                width: auto;
            }

            &__info {
                transform: none;
                position: fixed;
            }
        }

        &-pictures {
            display: block;

            &>div {
                width: 100%;
                margin-bottom: 1rem;
            }
        }

        &-zoom {
            svg {
                right: 2rem;
                top: 2rem;
            }

            img {
                width: 95vw;
            }
        }

        &-carousel__item {
            height: 17rem;

            img {
                width: 100%;
            }
        }

        &-carousel__double {
            height: auto;

            .gallery-carousel__item {
                height: 8rem;

                &:first-child {
                    margin-bottom: 1rem;
                }
            }
        }

        &-carousel__arrows {
            margin-bottom: 2rem;

            span svg {
                height: 2.5rem;
            }
        }

        &-wrap {
            display: block;

            &::after {
                display: none;
            }

            .container {
                padding-right: 15px;
            }
        }
    }
}
</style>

<style scoped>
    .swiper-container-free-mode > .swiper-wrapper{
        transition-timing-function : linear;
    }
</style>