<template>
    <div class="news-item">
        <img :src="$hostname + image" alt="News" class="news-item__img" v-if="image">
        <div class="image-back" v-else>
            {{$t('news-title')}}
        </div>
        <div class="news-item__date">
            {{date}}
        </div>
        <div class="news-item__text">
            <p>
                {{text}}
            </p>
        </div>
        <router-link :to="{path: '/news/' + id}" class="news-item__link"></router-link>
    </div>
</template>

<script>
export default {
    props: {
        image: String,
        date: String,
        text: String,
        id: Number
    }
}
</script>

<style lang="less">
.news-item {
    overflow: hidden;
    border-radius: .8rem;
    border: .2rem solid #E4E4E4;
    position: relative;
    height: 22rem;
    transition: .3s all;

    &__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
    }

    &__date {
        position: absolute;
        left: 1rem;
        top: 1rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: var(--yellow);
        width: 8rem;
        height: 2.1rem;
        border-radius: 2.1rem;
        color: #555;
        font-size: 0.7rem;
        font-weight: 600;
        opacity: 0;
        transition: .3s all;
    }

    &__text {
        padding: 1.2rem;
        border-radius: .8rem;
        border: .1rem solid transparent;
        position: absolute;
        left: 5%;
        width: 90%;
        bottom: 1.6rem;
        background: #fff;
        transition: .3s all;

        p {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 1.6rem;
            height: 3.2rem;
            text-overflow: ellipsis;
        }
    }

    &:hover {
        border-color: var(--yellow);
        box-shadow: 2px .6rem .7rem rgba(0, 0, 0, 0.25), .4rem .8rem 1rem rgba(0, 0, 0, 0.15);

        .news-item {

            &__date {
                opacity: 1;
            }

            &__text {
                border-color: #cc8401;
            }
        }
    }

    &__link {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
}
</style>