import Vue from 'vue'
import VueI18n from 'vue-i18n'
import uz from './lang/uz'
import ru from './lang/ru'
import en from './lang/en'



Vue.use(VueI18n)

const messages = {
    uz,
    ru,
    en
}

let lang 

if(sessionStorage.lang) {
    lang = sessionStorage.lang
} else {
    lang = "ru"
}
  
  export const i18n = new VueI18n({
    locale: lang,
    messages 
  })