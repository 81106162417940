import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Products from '../views/Products.vue'
import ProductSingle from '../views/ProductSingle.vue'
import News from '../views/News.vue'
import NewsSingle from '../views/NewsSingle.vue'
import Partners from '../views/Partners.vue'
import Contact from '../views/Contact.vue'
import Page404 from '../views/Page404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/products/:id',
    name: 'ProductSingle',
    component: ProductSingle
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/news/:id',
    name: 'NewsSingle',
    component: NewsSingle
  },
  {
    path: '/partners',
    name: 'Partners',
    component: Partners
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  { path: "*", 
    name: 'Page404',
    component: Page404 
  }
]

const router = new VueRouter({
  mode: 'history', 
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  linkExactActiveClass: "",
})

export default router
