<template>
    <section class="contact">
        <div class="about-sweets">
            <img src="../assets/img/milk.png" alt="Cookies" class="about-sweets__milk" 
            v-rellax="sweets">
            <img src="../assets/img/waffles.png" alt="Cookies" class="about-sweets__cookies" 
            v-rellax="sweets">
            <img src="../assets/img/cookies.png" alt="Waffles" class="about-sweets__waffles" 
            v-rellax="sweets">
        </div>
        <div class="container">
            <div class="contact-left">
                <h3 class="contact__title">
                    {{$t('contact.alibaba')}}
                </h3>
                <div class="contact__img">
                    <img src="../assets/img/contact.png" alt="contact">
                </div>
                <a href="https://uz1420968845azcq.trustpass.alibaba.com/" target="_blank"
                rel="nofollow" class="btn contact__btn">{{$t('contact.shop')}}</a>
                <div class="contact-numbers">
                    <div class="contact-numbers__item">
                        <div class="contact-numbers__name">
                            {{$t('contact.import')}}
                        </div>
                        <div class="contact-numbers__value">
                            <number :to="30000" :duration="3"/>
                        </div>
                        <div class="contact-numbers__weight">
                            {{$t('contact.weight')}}
                        </div>
                    </div>
                    <div class="contact-numbers__item">
                        <div class="contact-numbers__name">
                            {{$t('contact.export')}}
                        </div>
                        <div class="contact-numbers__value">
                            <number :to="1549" :duration="3"/>
                        </div>
                        <div class="contact-numbers__weight">
                            {{$t('contact.weight')}}
                        </div>
                    </div>
                </div>
                <div class="contact__info">
                    {{$t('contact.info')}}
                </div>
            </div>
            <div class="contact-right">
                <h3 class="contact__title">
                    {{$t('contact.req')}}
                </h3>
                <div class="contact-req">
                    <div class="contact-req__item">
                        <span>ООО «BONU SHIRINLIKLARI»</span> <br>
                        {{$t('contact.address')}}
                    </div>
                    <div class="contact-req__item">
                        <span>{{$t('contact.in')}}: </span>
                        301133957
                    </div>
                    <div class="contact-req__item">
                        <span>{{$t('contact.check')}}:</span>
                        2020 8840 8047 7277 5002 <br>
                        SC People's bank Jizzak regional branch
                    </div>
                    <div class="contact-req__item">
                        <span>{{$t('contact.bank')}}: </span>
                        00676
                    </div>
                    <div class="contact-req__item">
                        <span>{{$t('contact.swift')}}: </span>
                        SCPEUZ22
                    </div>
                    <div class="contact-req__item">
                        <span>{{$t('contact.corres')}}: </span>
                        Raiffeisen Bank International Am Stadtpark 9, 1030 Vienna, Austria
                    </div>
                    <div class="contact-req__item">
                        <span>{{$t('contact.bankCheck')}}: </span>
                        70-55.090.104
                    </div>
                    <div class="contact-req__item">
                        <span>SWIFT:</span>
                        RZBAATWW
                    </div>
                </div>
                <a href="#" class="btn contact-follow" @click.prevent="openFeedback">
                    {{$t('buttons.contact')}}
                </a>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    mounted() {
        window.scrollTop = 1000
        this.$options.metaInfo.title = this.$i18n.t('meta.contact.title')
        this.$options.metaInfo.meta[0].content = this.$i18n.t('meta.main.description')
        this.$options.metaInfo.meta[1].content = this.$i18n.t('meta.contact.keywords')
    },
    methods: {
        openFeedback() {
            this.$emit('openFeedback')
        }
    },
    data() {
        return {
            sweets: {
                speed: 4
            },
        }
    },

    metaInfo: {
        title: '',
            meta: [
                { name: 'description', content: '' },
                { name: 'keywords', content: '' },
            ]
        },
    }
</script>

<style lang="less">
.contact {
    background: #FFF9EC;
    padding: 9rem 0 2.5rem;

    .about-sweets img{
        width: 10rem;
    }

    .about-sweets__milk {
        top: 80vh;
    }

    .about-sweets__cookies {
        top: 10vh;
        left: -4rem;
    }

    .about-sweets__waffles {
        top: 60vh;
    }

    .container  {
        display: flex;
    }

    &-right {
        width: 30rem;
        flex-shrink: 0;
        margin-left: 5rem;
    }

    &-left {
        text-align: center;
    }
    
    &__title {
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 2rem;
    }

    &__img {
        margin-bottom: 1.7rem;

        img {
            width: 100%;
        }
    }

    &__btn {
        margin-bottom: 2.5rem;
    }

    &-numbers {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 2.5rem;

        &__name {
            font-weight: 600;
            color: #D3D1D1;
            font-size: 3.2rem;
        }

        &__value {
            font-size: 6rem;
            background: -webkit-linear-gradient(0deg, #BD9E3E, var(--yellow));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: bold;
            line-height: 100%;
        }

        &__weight {
            color: #000;
            font-size: 1.8rem;
            font-weight: 600;
        }
    }

    &__info {
        font-size: 1.2rem;
        color: #545454;
        line-height: 167%;
        max-width: 40rem;
        margin: 0 auto;
    }

    &-req {
        font-size: 1.2rem;
        line-height: 167%;
        margin-bottom: 3rem;

        &__item {
            color: #545454;

            span {
               color: #845F00;
               font-weight: bold; 
            }
        }
    }


}

@media (max-width: 768px), (max-width: 1080px) and (orientation: portrait) {
    .contact {
        .container {
            display: block;
        }

        .about-sweets {
            display: none;
        }

        &-right {
            margin-left: 0;
            margin-top: 2rem;
            width: auto;
            max-width: 30rem;
        }

        &-numbers {

            &__name {
                font-size: 2.1rem;
            }

            &__value {
                font-size: 4.2rem;
            }

            &__weight {
                font-size: 1.4rem;
            }
        }
    }
}
</style>